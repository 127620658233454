import $ from 'jquery';

import { $Elements, Classes, Elements, Events } from '../global/constants';
import { ajax } from '../global/helpers';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Handle Form ----------

	function handleForm(form, params) {

		const formContent = form.find('.form-content');
		const formDone = form.find('.form-done');
		const formSuccess = formDone.find('.success');
		const formError = formDone.find('.error');
		const formButton = form.find('button[type=submit]');

		form.on('submit', e => e.preventDefault());

		form.parsley().on('form:submit', async function() {

			Elements.LoadingBar.classList.add(Classes.loading);
			formButton.attr('disabled', true);

			const formData = new FormData(form[0]);
			formData.append('action', params.action);
			formData.append('nonce', params.nonce);

			try {

				const response = await ajax({
					url: params.url,
					type: 'POST',
					dataType: 'json',
					data: formData,
					processData: false,
					contentType: false,
				});

				formSuccess.removeClass(Classes.hidden);
				formError.addClass(Classes.hidden);

				if (form.hasClass('update-adherent-form')) {
					setTimeout(function() {
							window.location.href = '<?php echo tc_get_permalink(ADHERENT_PAGE); ?>';
					}, 20000);
			}
			}
			catch(e) {
				formError.removeClass(Classes.hidden);
			}

			Elements.LoadingBar.classList.remove(Classes.loading);
			formButton.attr('disabled', false);
			formContent.addClass(Classes.hidden);
			formDone.removeClass(Classes.hidden);

			return false;
		});
	}

	// ---------- Contact Form ----------

	if(typeof contact_form_params !== 'undefined') {

		$('form.contact-form, form.contact-form-fremoire').each(function() {
			handleForm($(this), contact_form_params);
		});
	}

	if(typeof update_form_params !== 'undefined') {

		$('form.update-adherent-form').each(function() {
			handleForm($(this), update_form_params);
		});
	}
});
