import $ from 'jquery';
import GLightbox from 'glightbox';

import { $Elements, Classes, Elements, Events } from '../global/constants';
import { ajax, initLightboxes } from '../global/helpers';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Ajax Posts ----------

	if (typeof ajax_posts_params !== 'undefined') {
		function initForm(form) {
			const button = form.find('button.ajax-posts-btn');
			const page = form.find('input[name=page]');
			const filters = form.find('.ajax-posts-filter');
			const content = form.find('.ajax-posts');
			const noResults = form.find('.no-results');
			const newSearch = form.find('button.new-search');


			button.on('click', () => form.data('append', true));

			newSearch.on('click', () => {
				const searchInput = form.find('.search-input');
				const searchValue = searchInput.val();
				form.find('input[name=search]').val(searchValue);
				refreshPosts(form, searchValue);
			});

			filters.on('change', () => refreshPosts(form));

			form.on('submit', async e => {
				e.preventDefault();
				e.stopImmediatePropagation();
				e.stopPropagation();

				button.attr('disabled', true);
				Elements.LoadingBar.classList.add(Classes.loading);

				const form_data = new FormData(form[0]);
				form_data.append('action', ajax_posts_params.action);
				form_data.append('nonce', ajax_posts_params.nonce);

				try {
					const response = await ajax({
						url: ajax_posts_params.admin_url,
						type: 'POST',
						dataType: 'json',
						data: form_data,
						processData: false,
						contentType: false,
					});

					if (response.data.content) {
						noResults.addClass(Classes.hidden);

						if (!form.data('append')) {
							content.empty();
						}

						content.append($(response.data.content));
						content.removeClass(Classes.hidden);
						initLightboxes();

					} else {
						content.addClass(Classes.hidden);
						noResults.removeClass(Classes.hidden);
					}

					if (response.data.next_page === 0) {
						button.addClass(Classes.hidden);
						button.attr('disabled', true);
					} else {
						page.val(response.data.next_page);
						button.removeClass(Classes.hidden);
					}
				} catch (e) {
					content.addClass(Classes.hidden);
					noResults.removeClass(Classes.hidden);
				}

				button.attr('disabled', false);
				Elements.LoadingBar.classList.remove(Classes.loading);

				return false;
			});
		}

		function refreshPosts(form) {
			const page = form.find('input[name=page]');
			form.data('append', false);
			page.val(1);

			form.submit();

		}

		$('form.ajax-posts-form').each(function() {
			const currentForm = $(this);
			initForm(currentForm);
		});
	}
});

