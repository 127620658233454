import $ from 'jquery';

import { $Elements, Classes, Elements, Events } from '../global/constants';
import { ajax } from '../global/helpers';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Downloads ----------

	if(typeof media_library_params !== 'undefined') {

		$('form.downloads-form').each(function() {

			const form = $(this);
			const button = form.find('button[type=submit]');
			const selectAll = form.find('button.select-all');
			const checkboxes = form.find('input[type=checkbox]');

			selectAll.text(selectAll.data('select-label'));

			selectAll.on('click', function() {
				const deselectClass = 'deselect-all';

				if(selectAll.hasClass(deselectClass)) {
					selectAll.removeClass(deselectClass);
					selectAll.text(selectAll.data('select-label'));
					checkboxes.attr('checked', false);
				}
				else {
					selectAll.addClass(deselectClass);
					selectAll.text(selectAll.data('deselect-label'));
					checkboxes.attr('checked', true);
				}

				checkboxes.trigger('change');
			});

			checkboxes.on('change', function() {
				const checked = form.find(':checked').length;

				button.find('span').text(checked);
				button.attr('disabled', !(checked > 0));
			});

			form.on('submit', async e => {
				e.preventDefault();
				e.stopImmediatePropagation();
				e.stopPropagation();

				button.attr('disabled', true);
				Elements.LoadingBar.classList.add(Classes.loading);

				const formData = new FormData(form[0]);
				formData.append('action', media_library_params.action);
				formData.append('nonce', media_library_params.nonce);

				try {

					const response = await ajax({
						url: media_library_params.admin_url,
						type: 'POST',
						dataType: 'json',
						data: formData,
					});

					window.location = response.data.file_url;
				}
				catch(e) {
				}

				button.attr('disabled', false);
				Elements.LoadingBar.classList.remove(Classes.loading);

				return false;
			});
		});
	}
});
